import React, { useContext } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { DateInput, ResetButton, Submit } from "components/UI"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ store, handleSubmit }) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <DateInput name="by_period_from" label="Период с" />
        </div>
        <div className="col-3">
          <DateInput name="by_period_to" label="Период по" />
        </div>
        <div className="col-3">
          {([7, 8].includes(currentUser.subdivision.id) && currentUser.id != 62) ||
          currentUser.id.toString() == "48" ? (
            <SubdivisionSelect name="by_subdivision_id" />
          ) : [7, 29].includes(currentUser.id) ? (
            <SubdivisionSelect
              name="by_subdivision_id"
              isOptionDisabled={(option) => !(currentUser.id === 7 ? [2, 4, 10] : [2, 4]).includes(option.value)}
            />
          ) : [10].includes(currentUser.id) ? (
            <SubdivisionSelect name="by_subdivision_id" isOptionDisabled={(option) => ![4, 5].includes(option.value)} />
          ) : [62].includes(currentUser.id) ? (
            <SubdivisionSelect
              name="by_subdivision_id"
              isOptionDisabled={(option) => ![1, 2, 5, 7, 9, 11].includes(option.value)}
            />
          ) : [70].includes(currentUser.id) ? (
            <SubdivisionSelect name="by_subdivision_id" isOptionDisabled={(option) => ![2].includes(option.value)} />
          ) : (
            <SubdivisionSelect
              name="by_subdivision_id"
              value={{ label: currentUser.subdivision.name, value: currentUser.subdivision.id }}
              isDisabled
            />
          )}
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
