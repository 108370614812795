import React, { useEffect } from "react"
import { NavLink } from "components/UI"
import logo from "assets/images/logo.png"
import Badge from "react-bootstrap/Badge"
import { observer } from "mobx-react"
import NotificationStore from "stores/NotificationStore/List"

const notificationStore = NotificationStore.create()

const SideBar = () => {
  useEffect(() => {}, [notificationStore.fetch()])
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-text mx-3">
          <img src={logo} width="80" />
        </div>
      </a>
      <hr className="sidebar-divider my-0" />
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Продажи</div>
      <NavLink to="/contracts">
        <span>Договоры</span>
      </NavLink>
      <NavLink to="/reports/payments">
        <span>Реестр оплат</span>
      </NavLink>
      <NavLink to="/reports/contract_installments">
        <span>
          Реестр рассрочек&nbsp;
          {notificationStore.reports_contract_installments > 0 && (
            <Badge pill variant="danger">
              {notificationStore.reports_contract_installments}
            </Badge>
          )}
        </span>
      </NavLink>
      <NavLink to="/reports/prolongations">
        <span>
          Реестр продлений&nbsp;
          {notificationStore.reports_prolongations > 0 && (
            <Badge pill variant="danger">
              {notificationStore.reports_prolongations}
            </Badge>
          )}
        </span>
      </NavLink>
      <NavLink to="/reports/payment_plan">
        <span>
          План выплат СА&nbsp;
          {notificationStore.payments_plan > 0 && (
            <Badge pill variant="danger">
              {notificationStore.payments_plan}
            </Badge>
          )}
        </span>
      </NavLink>
      <NavLink to="/reports/payment_fact">
        <span>
          Факт выплат СА&nbsp;
          {/* {notificationStore.payments_fact > 0 && (
            <Badge pill variant="danger">
              {notificationStore.payments_fact}
            </Badge>
          )} */}
        </span>
      </NavLink>
      <NavLink to="/reports/cancel_prolongations">
        <span>Реестр отказов</span>
      </NavLink>
      <NavLink to="/contract_documents">
        <span>Реестр сдачи документов</span>
      </NavLink>
      <NavLink to="/losses">
        <span>Убытки</span>
      </NavLink>
      {/* 
      
      <NavLink to="/avrs">
        <span>Акты выполненых работ</span>
      </NavLink>
      <NavLink to="/bsos">
        <span>БСО</span>
      </NavLink> */}
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Курьерские отправки</div>
      <NavLink to="/couriers">
        <span>
          Заявки на курьера&nbsp;
          {notificationStore.couriers > 0 && (
            <Badge pill variant="danger">
              {notificationStore.couriers}
            </Badge>
          )}
        </span>
      </NavLink>
      <NavLink to="/destinations">
        <span>Типовые адресаты</span>
      </NavLink>
      <NavLink to="/routes">
        <span>Типовые маршруты</span>
      </NavLink>
      {/* <div className="sidebar-heading">Документооборот</div>
      <NavLink to="/inbox_document_flows">
        <span>Входящие</span>
      </NavLink>
      <NavLink to="/sent_document_flows">
        <span>Исходящие</span>
      </NavLink> */}
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Отчеты</div>
      <NavLink to="/reports/sales">
        <span>Продажи</span>
      </NavLink>
      <NavLink to="/reports/new_sales">
        <span>Продажи new</span>
      </NavLink>
      <NavLink to="/reports/analytics_kv">
        <span>Аналитика КВ (до 2024)</span>
      </NavLink>
      <NavLink to="/reports/new_analytics_kv">
        <span>Аналитика КВ 2025+</span>
      </NavLink>
      <NavLink to="/reports/forecast">
        <span>Оценка оплат КВ</span>
      </NavLink>
      <NavLink to="/reports/new_kvs">
        <span>План факт КВ 2025+</span>
      </NavLink>
      <NavLink to="/reports/operations">
        <span>Отчет СП</span>
      </NavLink>
      {/* <NavLink to="/reports/kvs">
        <span>План факт КВ (до 2024)</span>
      </NavLink>
      <NavLink to="/reports/сouriers">
        <span>План факт курьеры</span>
      </NavLink>
      <NavLink to="/sale_plans">
        <span>Планы продаж</span>
      </NavLink> */}
      {/* <hr className="sidebar-divider" />
      <div className="sidebar-heading">Структура компании</div>
      <NavLink to="/subdivisions">
        <span>Подразделения</span>
      </NavLink>
      <NavLink to="/users">
        <span>Персонал</span>
      </NavLink>
      <NavLink to="/positions">
        <span>Должности</span>
      </NavLink>
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Приказы</div>
      <NavLink to="/order_subjects">
        <span>Темы приказов</span>
      </NavLink> */}
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Справочники</div>
      <NavLink to="/clients">
        <span>Страхователи</span>
      </NavLink>
      <NavLink to="/holdings">
        <span>Холдинги / Группы компаний</span>
      </NavLink>
      <NavLink to="/subagents">
        <span>Субагенты</span>
      </NavLink>
      {/* <NavLink to="/courts">
        <span>Суды</span>
      </NavLink> */}
      <NavLink to="/insurers">
        <span>Страховые компании</span>
      </NavLink>
      {/* <NavLink to="/car_brands">
        <span>Бренды авто</span>
      </NavLink>
      <NavLink to="/car_models">
        <span>Модели авто</span>
      </NavLink>
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Вспомогательные справочники</div>
      <NavLink to="/strengths">
        <span>Численность</span>
      </NavLink>
      <NavLink to="/document_kinds">
        <span>Документы</span>
      </NavLink>
      <NavLink to="/industries">
        <span>Отрасли</span>
      </NavLink>
      <NavLink to="/activity_kinds">
        <span>Виды услуг</span>
      </NavLink>
      <NavLink to="/appeals">
        <span>Обращения</span>
      </NavLink>
      <NavLink to="/sources">
        <span>Каналы поступления</span>
      </NavLink>
      <NavLink to="/risks">
        <span>Риски</span>
      </NavLink>
      <hr className="sidebar-divider" /> */}
      <div className="sidebar-heading">Технические справочники</div>
      {/* <NavLink to="/channels">
        <span>Каналы продаж</span>
      </NavLink> */}
      <NavLink to="/providers">
        <span>Поставщики</span>
      </NavLink>
      {/* <NavLink to="/insurance_kinds">
        <span>Виды страхования</span>
      </NavLink>
      <NavLink to="/bso_kinds">
        <span>Виды БСО</span>
      </NavLink> */}
      
      
      
      
      
      
      
      
      <hr className="sidebar-divider d-none d-md-block" />
      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle" />
      </div>
    </ul>
  )
}

export default observer(SideBar)
