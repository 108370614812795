import React, { useContext } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { SelectInput, ResetButton, Submit } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import AuthStoreContext from "contexts/AuthStoreContext"

const Select = ({ currentUser }) => {
  if (([7, 8].includes(currentUser.subdivision.id) && currentUser.id != 62) || currentUser.id.toString() == "48") {
    return <SubdivisionSelect name="by_subdivision_id" />
  }

  if ([7, 29].includes(currentUser.id)) {
    return (
      <SubdivisionSelect
        name="by_subdivision_id"
        isOptionDisabled={(option) => !(currentUser.id === 7 ? [2, 4, 10] : [2, 4]).includes(option.value)}
      />
    )
  }

  if ([10].includes(currentUser.id)) {
    return <SubdivisionSelect name="by_subdivision_id" isOptionDisabled={(option) => ![4, 5].includes(option.value)} />
  }

  if ([62].includes(currentUser.id)) {
    return (
      <SubdivisionSelect
        name="by_subdivision_id"
        isOptionDisabled={(option) => ![1, 2, 5, 7, 9, 11].includes(option.value)}
      />
    )
  }

  if ([70].includes(currentUser.id)) {
    return (
      <SubdivisionSelect
        name="by_subdivision_id"
        isOptionDisabled={(option) => ![2].includes(option.value)}
      />
    )
  }

  return null
}

const Filter = ({ store, setYear, handleSubmit }) => {
  const { data: currentUser } = useContext(AuthStoreContext)

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <SelectInput
            name="year"
            label="Год"
            options={[
              { label: "2020", value: 2020 },
              { label: "2021", value: 2021 },
              { label: "2022", value: 2022 },
              { label: "2023", value: 2023 },
              { label: "2024", value: 2024 }
            ]}
          />
        </div>
        <div className="col-3">
          <Select currentUser={currentUser} />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_maintenance_subdivision_id" label="Подразделение сопровождения" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter, year: 2024 }),
    handleSubmit: (values, formikBag) => {
      formikBag.props.setYear(values.year)
      filterSubmitHandler(values, formikBag)
    }
  }),
  observer
)(Filter)
