import React from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { SelectInput, ResetButton, Submit } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"

const Filter = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3">
        <SelectInput
          name="year"
          label="Год"
          options={[
            { label: "2020", value: 2020 },
            { label: "2021", value: 2021 },
            { label: "2022", value: 2022 },
            { label: "2023", value: 2023 },
            { label: "2024", value: 2024 }
          ]}
        />
      </div>
    </div>
    <Submit>Найти</Submit>
    &nbsp;
    <ResetButton />
  </form>
)

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter, year: 2024 }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
