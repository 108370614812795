import React, { createRef, Component } from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import analiticIcon from "assets/images/icons/analitic-kv.png"
import avrIcon from "assets/images/icons/avr.png"
import cashFlowIcon from "assets/images/icons/cash-flow.png"
import ddsIcon from "assets/images/icons/dds.png"
import debirReestrIcon from "assets/images/icons/debir-reestr.png"
import debitIcon from "assets/images/icons/debit.png"
import planFactKvIcon from "assets/images/icons/plan-fact-kv.png"
import budgetIcon from "assets/images/icons/budget.png"
import placementsIcon from "assets/images/icons/placements.png"

class TopBar extends Component {
  dropdownRef = createRef(null)

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick)
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick)
  }

  handleClick = (e) => {
    const { current } = this.dropdownRef
    if (current.contains(e.target)) return
    current.style.display = "none"
  }

  toggle = () => {
    const { current } = this.dropdownRef
    current.style.display = current.offsetParent === null ? "block" : "none"
  }

  onLogoutClick = () => this.context.logout().then(() => (window.location.href = "/auth/login"))

  render() {
    const {
      context: {
        data: { email, ...currentUser }
      },
      dropdownRef,
      onLogoutClick,
      toggle
    } = this

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Sidebar Toggle (Topbar) */}
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <i className="fa fa-bars" />
        </button>

        {[5, 23].includes(currentUser.id) && (
          <div className="row" style={{ width: "100%" }}>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/operations" style={{ textAlign: "center" }} title="ДДС">
                  <img style={{ height: "40px" }} src={ddsIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/reports/cashflow" style={{ textAlign: "center" }} title="Cash-flow">
                  <img style={{ height: "40px" }} src={cashFlowIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/placements/structure" style={{ textAlign: "center" }} title="Cash-flow">
                  <img style={{ height: "40px" }} src={placementsIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/reports/debits" style={{ textAlign: "center" }} title="Дебиторка">
                  <img style={{ height: "40px" }} src={debitIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/debits" style={{ textAlign: "center" }} title="Реестр дебиторки">
                  <img style={{ height: "40px" }} src={debirReestrIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/reports/new_analytics_kv" style={{ textAlign: "center" }} title="Аналитика КВ">
                  <img style={{ height: "40px" }} src={analiticIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/reports/new_kvs" style={{ textAlign: "center" }} title="План-факт КВ">
                  <img style={{ height: "40px" }} src={planFactKvIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/avrs" style={{ textAlign: "center" }} title="АВР">
                  <img style={{ height: "40px" }} src={avrIcon} />
                </a>
              </div>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: "40px",
                  minHeight: "40px",
                  padding: "0.75rem",
                  borderRadius: "5px",
                  textAlign: "center"
                }}
              >
                <a href="/budgets" style={{ textAlign: "center" }} title="Бюджет">
                  <img style={{ height: "40px" }} src={budgetIcon} />
                </a>
              </div>
            </div>
          </div>
        )}

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" onClick={toggle}>
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">{email}</span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
              ref={dropdownRef}
            >
              <a className="dropdown-item" href="#" onClick={onLogoutClick}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Выйти
              </a>
            </div>
          </li>
        </ul>
        {/* Topbar Navbar */}
      </nav>
    )
  }
}

TopBar.contextType = AuthStoreContext

export default TopBar
